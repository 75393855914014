var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category)?_c('main-layout',{attrs:{"title":_vm.category.name[_vm.$i18n.locale]},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.hasAds)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"header-props":{sortByText: _vm.$t('Sort by')},"items":_vm.ads,"items-per-page":500,"hide-default-footer":"","fixed-header":"","disable-pagination":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-1"},[(item.preview)?_c('v-img',{attrs:{"src":item.preview,"lazy-src":item.preview,"max-height":"70","max-width":"100"}}):_vm._e(),_c('div',{staticClass:"pl-2 mr-2"},[_c('router-link',{attrs:{"to":{ name: 'AdForm', params: {
                      id: item.id.toString(),
                      categoryConst: _vm.categoryConst,
                    }}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [(item.active[_vm.$i18n.locale] == '0' && item.status == 'listed')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('AdFormStatus.unlisted'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.isAdActive(item) ? 'AdFormStatus.listed' : ("AdFormStatus." + (item.status))))+" ")])]}},{key:"item.detail.instant",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",attrs:{"color":"white"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":Boolean(item.detail && item.detail.instant) ? 'green' : 'grey lighten-1'}},[_vm._v(" mdi-check-circle ")]),_vm._v(" "+_vm._s(_vm.$t(Boolean(item.detail && item.detail.instant) ? 'Yes' : 'No' ))+" ")],1)]}},{key:"item.calendarUrl",fn:function(ref){
                    var item = ref.item;
return [(['pending', 'pending_fix', 'listed', 'unlisted', 'active'].includes(item.status))?_c('v-btn',{staticClass:"mr-3",attrs:{"to":{name: 'Calendar', params: {
                  categoryConst: _vm.$route.params.categoryConst,
                  id: String(item.id)
                }},"x-small":"","fab":"","color":"primary","dark":""}},[_c('v-icon',[_vm._v("mdi-calendar")])],1):_vm._e()]}},{key:"item.downloadUrl",fn:function(ref){
                var item = ref.item;
return [(item && item.contract && item.contract[("url_" + (_vm.$i18n.locale))])?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('a',{attrs:{"href":_vm.downloadContractUrl(item),"target":"_blank"},on:{"click":function(event) { _vm.handlePdfDownloadClick(event, item) }}},[_c('v-btn',{staticClass:"text-capitalize font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t('Download Cooperation Agreement'))+" ")])],1)]):_vm._e()]}},{key:"item.options",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.category.id == 1)?_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cursor-pointer text-capitalize rounded-xl mr-2",attrs:{"elevation":"0","color":"black","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.isAdActive(item))?_c('v-list-item',{on:{"click":function($event){_vm.showApartmentDeactivateModal = true; _vm.deactivateApartmentId = item.id}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-eye-off")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Deactivate accommodation'))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){_vm.showApartmentDeleteModal = true; _vm.deleteApartmentId = item.id}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Delete accommodation'))+" ")])],1)],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","color":"primary","to":{ name: 'AdForm', params: {
                        id: item.id.toString(),
                        categoryConst: _vm.categoryConst,
                      }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])]),(_vm.getAdSinglePagePreviewUrl(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('a',{attrs:{"href":_vm.getAdSinglePagePreviewUrl(item),"target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","fab":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('View')))])]):_vm._e()],1)]}}],null,false,2617493586)}),_c('accommodation-deactivate-modal',{attrs:{"show":_vm.showApartmentDeactivateModal,"apartment-id":_vm.deactivateApartmentId},on:{"close":function($event){_vm.showApartmentDeactivateModal = false; _vm.deactivateApartmentId = undefined}}}),_c('accommodation-delete-modal',{attrs:{"show":_vm.showApartmentDeleteModal,"apartment-id":_vm.deleteApartmentId},on:{"close":function($event){_vm.showApartmentDeleteModal = false; _vm.deleteApartmentId = undefined}}})],1):_c('div',[_vm._v(" You have no listing, plese add your listing. "),_c('br'),_c('br'),(_vm.categoryConst)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":("/listings/" + _vm.categoryConst + "/create")}},[_vm._v(" "+_vm._s(_vm.$t('Create Ad')))]):_vm._e()],1)]},proxy:true}],null,false,308715492)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }