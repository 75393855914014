<template>
  <main-layout
    v-if="category"
    :title="category.name[$i18n.locale]">
    <template v-slot:body>
        <div v-if="hasAds">
            <v-data-table
              :headers="headers"
              :header-props="{sortByText: $t('Sort by')}"
              :items="ads"
              :items-per-page="500"
              hide-default-footer
              fixed-header
              :disable-pagination="true"
            >
              <template v-slot:item.name="{ item }">
                <div class="d-flex align-center py-1">
                  <v-img
                    v-if="item.preview"
                    :src="item.preview"
                    :lazy-src="item.preview"
                    max-height="70"
                    max-width="100"
                  ></v-img>
                  <div class="pl-2 mr-2">
                    <router-link
                      :to="{ name: 'AdForm', params: {
                        id: item.id.toString(),
                        categoryConst: categoryConst,
                      }}"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        x-small
                        fab
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :to="{ name: 'AdForm', params: {
                          id: item.id.toString(),
                          categoryConst: categoryConst,
                        }}"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('Edit')}}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="getAdSinglePagePreviewUrl(item)">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        :href="getAdSinglePagePreviewUrl(item)"
                        target="_blank"
                      >
                        <v-btn
                          x-small
                          fab
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                      </a>
                    </template>
                    <span>{{$t('View')}}</span>
                  </v-tooltip> -->
                </div>
              </template>

              <template v-slot:item.status="{ item }">
                <span v-if="item.active[$i18n.locale] == '0' && item.status == 'listed'">
                  {{ $t('AdFormStatus.unlisted') }}
                </span>
                <span v-else>
                  {{ $t(isAdActive(item) ? 'AdFormStatus.listed' : `AdFormStatus.${item.status}`) }}
                </span>
              </template>

              <template v-slot:item.detail.instant="{ item }">
                <div
                  class="d-flex align-center"
                  color="white"
                >
                  <v-icon
                    :color="Boolean(item.detail && item.detail.instant) ? 'green' : 'grey lighten-1'"
                    class="mr-1"
                  >
                    mdi-check-circle
                  </v-icon>
                  {{ $t(Boolean(item.detail && item.detail.instant) ? 'Yes' : 'No' ) }}
                </div>
              </template>

              <template v-slot:item.calendarUrl="{ item }">
                <v-btn
                  v-if="['pending', 'pending_fix', 'listed', 'unlisted', 'active'].includes(item.status)"
                  :to="{name: 'Calendar', params: {
                    categoryConst: $route.params.categoryConst,
                    id: String(item.id)
                  }}"
                  x-small
                  fab
                  color="primary"
                  class="mr-3"
                  dark
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.downloadUrl="{ item }">
                <v-menu offset-y v-if="item && item.contract && item.contract[`url_${$i18n.locale}`]">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <a
                    :href="downloadContractUrl(item)"
                    target="_blank"
                    @click="function(event) { handlePdfDownloadClick(event, item) }"
                  >
                    <v-btn class="text-capitalize font-weight-regular">
                      {{ $t('Download Cooperation Agreement') }}
                    </v-btn>
                  </a>
                </v-menu>
              </template>

              <template v-slot:item.options="{ item }">
                <div class="d-flex align-center">
                  <v-menu
                    bottom
                    right
                    offset-y
                    v-if="category.id == 1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="cursor-pointer text-capitalize rounded-xl mr-2"
                        elevation="0"
                        color="black"
                        icon
                      >
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-if="isAdActive(item)"
                        @click="showApartmentDeactivateModal = true; deactivateApartmentId = item.id"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-eye-off</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ $t('Deactivate accommodation') }}
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="showApartmentDeleteModal = true; deleteApartmentId = item.id"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ $t('Delete accommodation') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :to="{ name: 'AdForm', params: {
                          id: item.id.toString(),
                          categoryConst: categoryConst,
                        }}"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('Edit')}}</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="getAdSinglePagePreviewUrl(item)">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        :href="getAdSinglePagePreviewUrl(item)"
                        target="_blank"
                      >
                        <v-btn
                          x-small
                          fab
                          class="ml-2"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                      </a>
                    </template>
                    <span>{{$t('View')}}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>

            <accommodation-deactivate-modal
              :show="showApartmentDeactivateModal"
              :apartment-id="deactivateApartmentId"
              @close="showApartmentDeactivateModal = false; deactivateApartmentId = undefined"
            />

            <accommodation-delete-modal
              :show="showApartmentDeleteModal"
              :apartment-id="deleteApartmentId"
              @close="showApartmentDeleteModal = false; deleteApartmentId = undefined"
            />
        </div>
        <div v-else>
          You have no listing, plese add your listing.
          <br/>
          <br/>
          <router-link
            v-if="categoryConst"
            :to="`/listings/${categoryConst}/create`"
            class="text-decoration-none">
              {{ $t('Create Ad') }}</router-link>
        </div>
    </template>
  </main-layout>
</template>
<script>
import MetaTitleMixin from '@/lib/MetaTitleMixin';
import ApartmentMixin from '@/lib/ApartmentMixin';
import AccommodationDeactivateModal from '@/components/AccommodationDeactivateModal.vue';
import AccommodationDeleteModal from '@/components/AccommodationDeleteModal.vue';

export default {
  mixins: [MetaTitleMixin, ApartmentMixin],
  components: {
    AccommodationDeactivateModal,
    AccommodationDeleteModal,
  },
  props: {
    categoryConst: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      ads: [],
      category: undefined,

      showApartmentDeactivateModal: false,
      deactivateApartmentId: undefined,

      showApartmentDeleteModal: false,
      deleteApartmentId: undefined,
    };
  },
  watch: {
    categoryConst() {
      this.load();
    },
  },
  computed: {
    headers() {
      let headers = [];
      if (this.category.id === 1) {
        headers = [
          {
            text: this.$t('Name'),
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Status', value: 'status' },
          { text: this.$t('Instant reservation'), value: 'detail.instant' },
          { text: this.$t('Calendar'), value: 'calendarUrl' },
          { text: this.$t('Contract'), value: 'downloadUrl' },
          { text: this.$t('Options'), value: 'options' },
        ];
      } else {
        headers = [
          {
            text: this.$t('Name'),
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Status', value: 'status' },
          { text: this.$t('Instant reservation'), value: 'detail.instant' },
          { text: this.$t('Contract'), value: 'downloadUrl' },
          { text: this.$t('Options'), value: 'options' },
        ];
      }
      return headers;
    },
    hasAds() {
      return this.ads.length !== 0;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$store.dispatch('loading', true);
      this.$store
        .dispatch('getAdCategoryByConst', this.categoryConst)
        .then((category) => {
          if (!category) {
            this.$router.push({ name: '404' });
            this.$store.dispatch('loading', false);
            return;
          }
          this.category = category;
          this.setMetaTitle();
        });

      // this.$store.dispatch('loading', true);
      // // if no apartments - show CTA add your listing

      this.$store
        .dispatch('getAdsForCategoryConst', this.categoryConst)
        .then((ads) => {
          this.ads = ads;
          this.$store.dispatch('loading', false);
        });
    },
    downloadContractUrl(item) {
      return `${process.env.VUE_APP_APP_URL}/contract-download/ads/${item.id}/${this.$i18n.locale}`;
    },
    handlePdfDownloadClick(e, item) {
      // send S3 pdf url to Cordova app InAppBrowser (doesn't support regular API download)
      if (!window.cordova_iab) {
        return;
      }
      if (item.contract && item.contract[`url_${this.$i18n.locale}`]) {
        e.preventDefault();
        const S3Url = 'https://bookaweb.s3.eu-central-1.amazonaws.com';
        const contractUrl = `${S3Url}/${item.contract[`url_${this.$i18n.locale}`]}`;

        window.cordova_iab.postMessage(JSON.stringify({ url: contractUrl, type: 'pdf' }));
      }
    },
    setMetaTitle() {
      this.$title = this.category.name[this.$i18n.locale];
    },
  },
};
</script>
